import React, {FC, useMemo} from 'react';
import styles from './preview-card-profile.module.scss';
import { ReactComponent as EmptyContentImg } from '../../assets/icons/empty-profile.svg';
import SocialLinks from '../../components/previewCardProfile/socialLinks/SocialLinks';
import Contacts from '../../components/previewCardProfile/contacts/Contacts';
import {getExternalUrl, notBlank, notEmpty, openInNewTab} from '../../utils/common';
import i18n from '../../translations/i18n';
import { useToggleContext } from '../../context/toggle/ToggleProvider';
import PreviewFooter from './PreviewFooter';
import {isWebsiteContactType} from '../../utils/contact/contact';
import {
    CardProfile,
    CardProfileAbout,
    CardProfileAddress, CardProfileBasic,
    CardProfileContact,
    CardProfileSocialLink, CardProfileStyle,
} from '../../types/cardProfile';

interface CardProfileContentProps {
    profileBasic?: CardProfileBasic;
    profileContacts: CardProfileContact[];
    profileSocialLinks: CardProfileSocialLink[];
    profileAddresses?: CardProfileAddress;
    profileAbout?: CardProfileAbout;
    profileStyle?: CardProfileStyle;
    isEmptyContent?: boolean;
    onSave: () => void;
    cardProfile?: CardProfile;
}

const CardProfileContent: FC<CardProfileContentProps> = ({
    profileBasic, profileContacts, profileSocialLinks, profileAddresses, profileAbout, profileStyle, onSave, isEmptyContent, cardProfile
}) => {
    const { profileToggle } = useToggleContext();

    const websiteUrl = useMemo(
        () => profileContacts.find(c => isWebsiteContactType(c.displayType))?.details, [profileContacts]
    );

    const handleOpenCompanyWebsite = () => websiteUrl && openInNewTab(getExternalUrl(websiteUrl));

    if (isEmptyContent) {
        return (
            <div className={styles.emptyContent}>
                <div className={styles.infoContainer}>
                    <div
                        className={styles.fullname}
                        style={{color: profileStyle?.cardTextColor}}
                    >
                        {profileBasic?.fullname}
                    </div>
                </div>
                <EmptyContentImg className={styles.emptyImg}/>
                <div className={styles.emptyText}>
                    {profileBasic?.fullname} {i18n.t('profile.noContentAdded')}
                </div>
                <PreviewFooter profileStyle={cardProfile?.profileStyle} />
            </div>
        );
    }

    return (
        <>
            <div className={styles.infoContainer}>
                <div className={styles.fullname} style={{color: profileStyle?.cardTextColor}}>
                    {profileBasic?.fullname}
                </div>
                <div className={styles.positionWrapper}>
                    <div className={styles.position} style={{color: profileStyle?.cardTextColor}}>
                        {profileBasic?.position}
                    </div>
                    {notBlank(profileBasic?.department) &&
                        <div className={styles.position} style={{color: profileStyle?.cardTextColor}}>
                            {profileBasic?.department}
                        </div>
                    }
                </div>
                <div
                    className={styles.company}
                    style={{color: profileStyle?.companyTextColor, backgroundColor: profileStyle?.companyBgColor}}
                    onClick={handleOpenCompanyWebsite}
                >
                    {profileBasic?.company}
                </div>
                <button
                    className={`${styles.text} ${styles.btn}`}
                    onClick={onSave}
                    style={{backgroundColor: profileStyle?.btnBgColor, color: profileStyle?.btnTextColor}}
                >
                    {i18n.t('common.saveContact')}
                </button>
            </div>
            {profileToggle?.socialLinks && profileSocialLinks &&
                <SocialLinks
                    iconStyle={{fill: '#ffffff'}}
                    style={{ backgroundColor: profileStyle?.iconsBgColor}}
                    profileSocialLinks={profileSocialLinks}
                />
            }
            {(notEmpty(profileContacts) || notEmpty(profileAddresses)) && profileToggle?.contact &&
                <>
                    <div className={styles.divider}></div>
                    <Contacts
                        textStyle={{color: profileStyle?.cardTextColor}}
                        style={{ backgroundColor: profileStyle?.iconsBgColor}}
                        iconStyle={{ fill: profileStyle?.iconsColor ? profileStyle?.iconsColor :'#ffffff'}}
                        profileContacts={profileContacts}
                        profileAddresses={profileAddresses}
                    />
                </>
            }
            { profileToggle?.about && profileAbout &&
            <>
                <div className={styles.divider}></div>
                <div className={styles.aboutContainer}>
                    <div className={styles.title} style={{ color: profileStyle?.cardTextColor}}>
                        {i18n.t('common.about')}
                    </div>
                    <div style={{ color: profileStyle?.cardTextColor}}>
                        {profileAbout.text}
                    </div>
                </div>
            </>
            }
            <PreviewFooter profileStyle={cardProfile?.profileStyle} />
        </>
    );
};

export default CardProfileContent;