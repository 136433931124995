import React, {FC, useRef, useState} from 'react';
import styles from './card-box.module.scss';
import i18n from '../../../translations/i18n';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';
import {ReactComponent as ThreeDotsIcon} from '../../../assets/icons/three-dots.svg';
import {ReactComponent as StatusIcon} from '../../../assets/icons/contactless.svg';
import {ReactComponent as DefaultProfileIcon} from '../../../assets/icons/preview-avatar.svg';
import {
    ACTIVE,
    CardProfileStatus,
    INACTIVE,
    UserCardProfile
} from '../../../types/userCardProfile';
import MyCardsMenu from '../myCardsMenu/MyCardsMenu';
import {TextHighlighter} from '../../common/textHighlighter/TextHighlighter';
import {ShareModal} from '../../common/shareModal/ShareModal';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {updateCardProfileStatus} from '../../../api/cardProfile';
import AssignCardModal from '../cardModals/AssignCardModal';
import CustomTooltip from '../../common/customTooltip/CustomTooltip';
import ChangeCardOwnerModal from '../cardModals/ChangeCardOwnerModal';
import {IS_COMPANY_ADMIN} from '../../../store/utils/userSession';


const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

interface CardBoxProps {
    profile: UserCardProfile,
    onEdit: (cardProfileId: number) => void,
    onDelete: (cardProfileId: number) => void,
    onPreview: (uuid: string) => void,
    moveToCompanyCards: (cardProfileId: number) => Promise<void>,
    searchText: string,
    isMyCards?: boolean,
    reloadCardsApi?: () => void
}

const CardBox: FC<CardBoxProps> = ({
    profile, onEdit, onDelete, onPreview, moveToCompanyCards, searchText, isMyCards, reloadCardsApi
}) => {
    const user = useAppSelector(selectUser);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [openShareModal, setOpenShareModal] = useState<boolean>(false);
    const [openAssignCardModal, setOpenAssignCardModal] = useState<boolean>(false);
    const [openChangeCardOwnerModal, setOpenChangeCardOwnerModal] = useState<boolean>(false);
    const profilePhotoPath = profile.basic.profilePhotoPath;
    const profilePhotoUrl = (imageBaseUrl && profilePhotoPath) ? (imageBaseUrl + profilePhotoPath) : undefined;

    const managedByOtherUserFlag = (isMyCards && user.id !== profile.owner.id) ?? false;
    const canChangeOwner = (isMyCards && user.id === profile.owner.id) ?? false;
    const managedByCompanyFlag = !isMyCards && !IS_COMPANY_ADMIN();

    const menuIconRef = useRef<HTMLDivElement>(null);

    const handleUpdateCardProfileStatus = (status: CardProfileStatus) => {
        updateCardProfileStatus(profile.cardProfileId, status);
    };

    const handleOnEdit = () => onEdit(profile.cardProfileId);

    const handleModalView = () => {
        setTimeout(() => {
            setShowMenu(prevState => !prevState);
        }, 50);
    };

    const handleDelete = () => onDelete(profile.cardProfileId);

    const handlePreview = () => onPreview(profile.uuid);

    const handleMoveToCompany = () => moveToCompanyCards(profile.cardProfileId).then(() => setShowMenu(false));

    const isProfileActive = profile.status === ACTIVE;
    const isInactive = profile.status === INACTIVE;

    const profileIndicatorStyle = isProfileActive ? styles.active : isInactive ? styles.inactive : '';
    const tooltipMsg = isProfileActive ? i18n.t('common.activated') : isInactive ? i18n.t('common.inactive') : i18n.t('common.notActivated');

    return (
        <>
            <div className={styles.container}>
                <div className={styles.border}>
                    {managedByOtherUserFlag &&
                        <div className={styles.owner}>
                            Managed by {profile.owner.email}
                        </div>
                    }
                    {managedByCompanyFlag &&
                        <div className={styles.owner}>
                            Managed by {user.activeCompany?.name}
                        </div>
                    }
                    <CustomTooltip
                        title={
                            <div style={{ whiteSpace: 'pre-line', textAlign: 'center', fontSize: '14px' }}>
                                {tooltipMsg}
                            </div>
                        }
                        placement='bottom'
                    >
                        <div className={`${styles.statusWrapper} ${profileIndicatorStyle}`}>
                            <StatusIcon className={styles.dotsIcon}/>
                        </div>
                    </CustomTooltip>
                    <div className={styles.imageWrapper}>
                        <div className={styles.imageBox}>
                            {profilePhotoUrl
                                ? <img src={profilePhotoUrl} alt='profile-photo' className={styles.image}/>
                                : <DefaultProfileIcon className={styles.image}/>
                            }
                        </div>
                    </div>
                    <div className={styles.textBox}>
                        <div className={`${styles.text} ${styles.idText}`}>
                            #{profile.cardProfileId}
                        </div>
                        <div className={`${styles.text} ${styles.usernameText}`}>
                            <TextHighlighter
                                text={profile.basic?.fullname ?? ''}
                                textToHighlight={searchText}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <div
                        className={styles.footerSectionLeft}
                        onClick={handleOnEdit}
                    >
                        <EditIcon className={styles.editIcon}/>
                        <div className={`${styles.text} ${styles.btnText}`}>
                            {i18n.t('common.edit')}
                        </div>
                    </div>
                    <div
                        className={`${styles.footerSectionRight} ${showMenu && styles.menuActive}`}
                        onClick={handleModalView}
                        ref={menuIconRef}
                    >
                        <ThreeDotsIcon className={styles.moreIcon}/>
                        <div className={`${styles.text} ${styles.btnText} `}>
                            {i18n.t('common.more')}
                        </div>
                    </div>
                </div>
                {showMenu &&
                    <MyCardsMenu
                        handlePreview={handlePreview}
                        handleShare={() => setOpenShareModal(true)}
                        handleEdit={handleOnEdit}
                        handleAssignCardTo={() => setOpenAssignCardModal(true)}
                        handleChangeCardOwner={() => setOpenChangeCardOwnerModal(true)}
                        handleSwitchStatus={handleUpdateCardProfileStatus}
                        handleDelete={handleDelete}
                        isActive={isProfileActive}
                        handleMoveToCompany={handleMoveToCompany}
                        isMyCards={isMyCards}
                        onClose={() => setShowMenu(false)}
                        canChangeOwner={canChangeOwner}
                        menuIconRef={menuIconRef}
                    />
                }
            </div>
            <ShareModal
                isOpen={openShareModal}
                uuid={profile.uuid}
                onClose={() => setOpenShareModal(false)}
                fullName={user.fullname}
            />
            <AssignCardModal
                isOpen={openAssignCardModal}
                onClose={() => setOpenAssignCardModal(false)}
                cardProfileId={profile.cardProfileId}
                userId={user.id}
            />
            <ChangeCardOwnerModal
                isOpen={openChangeCardOwnerModal}
                onClose={() => setOpenChangeCardOwnerModal(false)}
                userId={user.id}
                uuid={profile.uuid}
                reloadCardsApi={reloadCardsApi}
            />
        </>
    );
};

export default CardBox;