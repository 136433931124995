import {FC, useMemo} from 'react';
import styles from './my-cards-header.module.scss';
import i18n from '../../../translations/i18n';
import {SearchInput} from '../../common/searchInput/SearchInput';
import {Selector} from '../../common/selector/Selector';
import {CARD_PROFILE_FILTER_STATUSES, CardProfileFilterStatus} from '../../../types/userCardProfile';
import {ReactComponent as FilterIcon} from '../../../assets/icons/filter.svg';
import OrderNewCardButton from '../../common/button/OrderNewCardButton';

interface MyCardsHeaderProps {
    onSearch: (text: string) => void;
    onSelect: (option: CardProfileFilterStatus) => void;
    userHasCardProfile: boolean;
    title: string;
}

const MyCardsHeader: FC<MyCardsHeaderProps> = ({
    onSearch, onSelect, userHasCardProfile, title
}) => {
    const filterItems = useMemo(() => CARD_PROFILE_FILTER_STATUSES.map(fi => ({value: fi, name: fi})), []);

    return (
        <div className={styles.container}>
            <div className={styles.text}>
                {title}
            </div>
            {userHasCardProfile &&
                <>
                    <div className={styles.orderCard}>
                        <OrderNewCardButton/>
                    </div>
                    <div className={styles.search}>
                        <SearchInput
                            placeholder={`${i18n.t('common.search')}...`}
                            onSearch={onSearch}
                            className={styles.input}
                        />
                        <Selector
                            label={i18n.t('labels.filterBy')}
                            items={filterItems}
                            defaultValue={CARD_PROFILE_FILTER_STATUSES[0]}
                            onSelect={onSelect as any}
                            icon={FilterIcon}
                            formStyle={styles.selector}
                        />
                    </div>
                </>
            }
        </div>
    );
};

export default MyCardsHeader;