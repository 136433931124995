import {useEffect, useState} from 'react';
import {getCompanyMembers} from '../../../api/company';
import {CompanyMember} from '../../../types/company';
import {CompanyConverter} from '../../../converters/api/CompanyConverter';
import {useAlert} from '../../../context/AlertProvider';
import i18n from '../../../translations/i18n';
import {ERROR} from '../../../constants/common';
import {COMPANY_ID} from '../../../store/utils/userSession';

export const useCompanyMemberController = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [companyMembers, setCompanyMembers] = useState<CompanyMember[]>([]);
    const {setAlert} = useAlert();

    const companyId = COMPANY_ID() as number;

    useEffect(() => {
        if (!companyId || loading) return;

        setLoading(true);
        getCompanyMembers(companyId)
            .then(res => {
                if (res?.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                    return;
                }

                const companyMemberData = CompanyConverter.convertFromCompanyMembersApiToCompanyMembers(res.data);
                companyMemberData && setCompanyMembers(companyMemberData);
            })
            .finally(() => setLoading(false));
    }, [companyId]);


    return {
        loading,
        companyMembers,
        companyId
    };
};