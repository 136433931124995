import {FC, useEffect, useMemo, useState} from 'react';
import ProfileHeader from '../../components/layout/ProfileHeader/ProfileHeader';
import useWalletCardController from './useWalletCardController';
import { useParams } from 'react-router-dom';
import Appearance from '../../components/profile/appearance/Appearance';
import General from '../../components/profile/general/General';
import styles from './wallet-card.module.scss';
import { FormProvider } from '../../context/profile/FormProvider';
import { ThemeProvider } from '../../context/theme/ThemeProvider';
import { useWalletCardDataContext } from '../../context/walletCard/WalletCardDataProvider';
import { usePrompt } from '../../hooks/usePrompt';
import useSaveWalletCardController from '../../context/walletCard/useSaveWalletCardController';
import { CardProfileConverter } from '../../converters/api/CardProfileConverter';
import AddToWalletButton from '../../components/common/button/AddToWalletButton';
import StickyFooter from '../../components/layout/stickyFooter/StickyFooter';
import IOSPreview from '../../components/walletCard/preview/IOSPreview';
import {isAppleDevice} from '../../utils/device';
import AndroidPreview from '../../components/walletCard/preview/AndroidPreview';

const WalletCard: FC = () => {
    const { id } = useParams();
    if (!id) return <></>;
    
    const {
        uuid, loading, walletBasicInfo, walletStyleInfo,
        profilePhoto, coverPhoto, cardProfilePhoto,setProfilePhoto, setCoverPhoto,
        walletCardData
    } = useWalletCardController(id);
    const {
        walletBasic, newWalletCoverPhoto, newWalletProfilePhoto, setWalletBasic,
        walletStyle, setWalletStyle, setNewWalletProfilePhoto, setNewWalletCoverPhoto,
        isWalletDirty, setIsWalletDirty, isCustom, setIsCustom
    } = useWalletCardDataContext();
    const { saveWalletCardInfo, walletLoading, handleAddWallet, addToWalletLoading } = useSaveWalletCardController(parseInt(id), walletCardData?.id);
    const [isSticky, setSticky] = useState(false);

    const cropShape = useMemo(() => isAppleDevice() ? 'rect' : 'round', []);

    usePrompt({
        isDirty: isWalletDirty,
    });

    useEffect(() => {
        setWalletBasic(walletBasicInfo);
        setWalletStyle(walletStyleInfo);
    }, [walletBasicInfo, walletStyleInfo]);

    const handleSaveInfos = () => {
        saveWalletCardInfo(
            profilePhoto,
            coverPhoto,
            CardProfileConverter.convertToBasic(walletBasic),
            walletStyle,
            newWalletProfilePhoto, 
            newWalletCoverPhoto
        );
        setProfilePhoto(newWalletProfilePhoto ? URL.createObjectURL(newWalletProfilePhoto as Blob) : profilePhoto);
        setCoverPhoto(newWalletCoverPhoto ? URL.createObjectURL(newWalletCoverPhoto as Blob) : coverPhoto);
        setIsWalletDirty(false);
    };

    const handleRemoveProfile = () => {
        setIsWalletDirty(true);
        setProfilePhoto('');
        setNewWalletProfilePhoto(undefined);
    };

    const handleRemoveCover = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsWalletDirty(true);
        setCoverPhoto('');
        setNewWalletCoverPhoto(undefined);
    };

    const checkScrollTop = () => {
        setSticky(window.scrollY >= 70);
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    }, []);

    if (loading || walletBasic === undefined) return <></>; 
    
    return (
        <ThemeProvider>
            <ProfileHeader
                uuid={uuid as string}
                status={walletBasicInfo?.status}
                saveInfos={handleSaveInfos}
                cardProfileId={parseInt(id)}
                loading={walletLoading}
                isDirty={isWalletDirty}
                setIsDirty={setIsWalletDirty}
                profilePhoto={cardProfilePhoto}
                isSticky={isSticky}
            />
            <FormProvider>
                <div className={`${styles.container} ${isSticky ? styles.sticky : ''}`}>
                    <div className={styles.leftContainer}>
                        {isAppleDevice()
                            ? <IOSPreview
                                walletBasic={walletBasic}
                                coverPhoto={coverPhoto}
                                profilePhoto={profilePhoto}
                                walletStyle={walletStyle}
                                uuid={uuid as string}
                            />
                            :  <AndroidPreview
                                walletBasic={walletBasic}
                                coverPhoto={coverPhoto}
                                profilePhoto={profilePhoto}
                                walletStyle={walletStyle}
                                uuid={uuid as string}
                            />
                        }
                        <AddToWalletButton
                            sx={styles.wallet}
                            loading={addToWalletLoading}
                            onAddWallet={handleAddWallet}
                        />
                    </div>
                    <div className={styles.rightContainer}>
                        <General
                            profileBasic={walletBasic}
                            coverPhoto={coverPhoto}
                            profilePhoto={profilePhoto}
                            setNewProfilePhoto={setNewWalletProfilePhoto} 
                            setNewCoverPhoto={setNewWalletCoverPhoto} 
                            setIsDirty={setIsWalletDirty}   
                            setBasicData={setWalletBasic}
                            handleRemoveProfile={handleRemoveProfile}  
                            handleDeleteCoverImage={handleRemoveCover}
                            newProfilePhoto={newWalletProfilePhoto}
                            newCoverPhoto={newWalletCoverPhoto}
                            isWalletCard
                            crop={cropShape}
                        />
                        <Appearance 
                            setIsDirty={setIsWalletDirty}
                            profileStyle={walletStyle}
                            setProfileStyle={setWalletStyle}
                            setSelectedColors={setWalletStyle}
                            selectedColors={walletStyle} 
                            isCustom={isCustom} 
                            setIsCustom={setIsCustom}
                            isWallet={true}
                        />
                    </div>
                </div>
                <StickyFooter
                    isDirty={isWalletDirty}
                    loading={walletLoading}
                    saveInfos={handleSaveInfos}
                />
            </FormProvider>
        </ThemeProvider>
    );
};

export default WalletCard;