import {
    CardProfileAbout,
    CardProfileActivateRequest, CardProfileAssignRequest,
    CardProfileSocialLinksUpdate,
    CardProfileStyle,
    CardProfileToggle,
    ContactAndAdress
} from '../types/cardProfile';
import {CardProfileApiFilter, CardProfileStatus} from '../types/userCardProfile';
import {
    configDefault,
    getApiCall,
    configDefaultWithToken,
    postApiCall,
    patchApiCall,
    configForImages,
    deleteApiCall
} from './base';


const cardProfileUrl = '/api/card-profile';
const cardProfilesUrl = '/api/card-profile/profiles';
const cardProfileByUuidUrl = `${cardProfileUrl}/uuid`;
const cardProfileAboutSectionUrl = `${cardProfileUrl}/about`;
const cardProfileGeneralSectionUrl = `${cardProfileUrl}/basic`;
const carProfileSocialLinksSectionUrl = `${cardProfileUrl}/social-links`;
const cardProfileContactSectionUrl = `${cardProfileUrl}/contact`;
const cardProfileAppearanceUrl = `${cardProfileUrl}/style`;
const cardProfileToggleUrl = `${cardProfileUrl}/toggle`;
const cardProfileActivateUrl = `${cardProfileUrl}/activate`;
const cardProfileAssignUrl = `${cardProfileUrl}/assign`;

const getUserCardProfilesBasicUrl = (id: number) => `${cardProfilesUrl}/basic/${id}`;

export const cardProfileImageByUuidUrl = `${cardProfileUrl}/profile-image/uuid`;
export const cardProfileCoverImageByUuidUrl = `${cardProfileUrl}/cover-image/uuid`;
export const cardProfileImageByIdUrl = `${cardProfileUrl}/profile-image`;
export const cardProfileCoverImageByIdUrl = `${cardProfileUrl}/cover-image`;
export const cardProfileStatusUrl = `${cardProfileUrl}/status`;
export const qrCodeUrl = '/api/qrcode';
export const cardProfilesBusinessUrl = (userId: number, companyId: number) => `${cardProfilesUrl}/${userId}/business/${companyId}`;
export const cardProfilesAssignUrl = (cardProfileId: number, companyId: number) => `${cardProfileAssignUrl}/${cardProfileId}/company/${companyId}`;

export const getCardProfilesByUserId = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(`${cardProfilesUrl}/${id}`, config);
};

export const getCardProfilesBusinessByUserId = async (userId: number, companyId: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(cardProfilesBusinessUrl(userId, companyId), config);
};

export const getCardProfileByUuid = async (uuid: string) => {
    const config = {
        ...(await configDefault())
    };

    return await getApiCall(`${cardProfileByUuidUrl}/${uuid}`, config);
};

export const getCardProfileImage = async (uuid: string) => {
    const config = {
        ...(await configDefault())
    };

    return await getApiCall(`${cardProfileImageByUuidUrl}/${uuid}`, config);
};

export const getCardProfileImageById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken()),
        responseType: 'arraybuffer'
    };

    return await getApiCall(`${cardProfileImageByIdUrl}/${id}`, config);
};

export const getCardProfileCoverImage = async (uuid: string) => {
    const config = {
        ...(await configDefault())
    };

    return await getApiCall(`${cardProfileCoverImageByUuidUrl}/${uuid}`, config);
};

export const getCardProfileById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(`${cardProfileUrl}/${id}`, config);
};

export const saveAboutSection = async (id: string, aboutData?: CardProfileAbout) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(`${cardProfileAboutSectionUrl}/${id}`, aboutData, config);
};

export const saveGeneralSection = async (id: string, data: FormData) => {
    const config = {
        ...(await configForImages()),
    };

    return await patchApiCall(`${cardProfileGeneralSectionUrl}/${id}`, data, config);
};

export const saveSocialLinksSection = async (id: string, links: CardProfileSocialLinksUpdate[]) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(`${carProfileSocialLinksSectionUrl}/${id}`, links, config);
};

export const saveContactSection = async (id: string, data: ContactAndAdress) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await patchApiCall(`${cardProfileContactSectionUrl}/${id}`, data, config);
};

export const getUserCardProfiles = async (userId: number, filter: CardProfileApiFilter) => {

    const config = {
        ...(await configDefaultWithToken()),
        params: {
            searchByName: filter.searchByName,
            status: filter.status,
            page: filter.page
        }
    };

    return await getApiCall(getUserCardProfilesBasicUrl(userId), config);
};

export const saveAppearanceSection = async (id: string, data?: CardProfileStyle) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await patchApiCall(`${cardProfileAppearanceUrl}/${id}`, data, config);
};

export const updateCardProfileStatus = async (id: number, data: CardProfileStatus) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await patchApiCall(`${cardProfileStatusUrl}/${id}`, data, config);
};

export const saveToggleSection = async (id: string, data: CardProfileToggle) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await patchApiCall(`${cardProfileToggleUrl}/${id}`, data, config);
};

export const deleteCardProfileById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await deleteApiCall(`${cardProfileUrl}/${id}`, config);
};

export const deleteCardProfileCoverImgById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await deleteApiCall(`${cardProfileCoverImageByIdUrl}/${id}`, config);
};

export const deleteCardProfileImgById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await deleteApiCall(`${cardProfileImageByIdUrl}/${id}`, config);
};

export const getQrcode = async (uuid: string) => {
    const config = {
        ...(await configDefaultWithToken()),
        responseType: 'arraybuffer'
    };

    return await getApiCall(`${qrCodeUrl}/${uuid}`, config);
};

export const saveProfilCardInfo = async (id: number, data: FormData) => {
    const config = {
        ...(await configForImages())
    };

    return await patchApiCall(`${cardProfileUrl}/${id}`, data, config);
};

export const activateCardProfile = async (uuid: string, data: CardProfileActivateRequest) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(`${cardProfileActivateUrl}/${uuid}`, data, config);
};

export const assignCardProfile = async (cardProfileId: number, companyId: number, data: CardProfileAssignRequest) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(cardProfilesAssignUrl(cardProfileId, companyId), data, config);
};