import { saveToggleSection } from '../../api/cardProfile';
import { CardProfileToggle } from '../../types/cardProfile';

const useToggleController = (id: string) => {
   
    const saveToggleData = (profileToggle: CardProfileToggle) => {

        return saveToggleSection(id, profileToggle)
            .then(
                /* TO DO */
            );
    };
     
    return {
        saveToggleData,
    };
};

export default useToggleController;