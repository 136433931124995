import React, {FC} from 'react';
import styles from './preview-card-profile.module.scss';
import { ReactComponent as PoweredByIcon } from '../../assets/icons/powered-by.svg';
import {openInNewTab} from '../../utils/common';
import i18n from '../../translations/i18n';

import {ICARDS_FULL_URL} from '../../constants/common';
import {
    CardProfileStyle,
} from '../../types/cardProfile';

interface PreviewFooterProps {
    profileStyle?: CardProfileStyle;
    sx?: any;
}

const PreviewFooter: FC<PreviewFooterProps> = ({
    profileStyle, sx
}) => {
    const navigateToWebshop = () => openInNewTab(ICARDS_FULL_URL);

    return (
        <div className={`${styles.poweredBy} ${sx}`}>
            <div
                className={styles.poweredByText}
                style={{ color: profileStyle?.cardTextColor }}
            >
                {i18n.t('profile.poweredBy')}
            </div>
            <div
                onClick={navigateToWebshop}
                style={{cursor: 'pointer'}}
            >
                <PoweredByIcon/>
            </div>
        </div>
    );
};

export default PreviewFooter;