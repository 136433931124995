import React, {FC, useEffect} from 'react';
import styles from './preview-card-profile.module.scss';
import usePreviewCardProfileController from './usePreviewCardProfileController';
import {isEmptyProfile, saveContact} from './previewCardProfieUtils';
import CardProfileContent from './CardProfileContent';
import useAnalyticController from './useAnalyticController';
import { ToggleProvider } from '../../context/toggle/ToggleProvider';
import {
    CardProfileAbout,
    CardProfileAddress,
    CardProfileBasic,
    CardProfileContact,
    CardProfileSocialLink, CardProfileStyle, CardProfileToggle,
} from '../../types/cardProfile';
import i18n from '../../translations/i18n';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import {FRENCH_VALUE, GERMAN_VALUE} from '../../types/language';
import {toggleLanguage} from '../../translations/i18nUtils';
import ActiveYourCardProfile from './ActiveYourCardProfile';
import PreviewFooter from './PreviewFooter';
import {UNASSIGNED} from '../../types/userCardProfile';


const privacyPolicyURL = process.env.REACT_APP_PRIVACY_POLICY_URL;

const PreviewCardProfile: FC = () => {
    const {cardProfileData, loading, profilePhoto, coverPhoto} = usePreviewCardProfileController();
    const isUnassigned = cardProfileData?.status === UNASSIGNED;

    const {handleAddContactSaveTrack} = useAnalyticController(cardProfileData != null && !isUnassigned);

    const user = useAppSelector(selectUser);
    const userLanguage = navigator.language;

    useEffect(() => {
        if (!user) {
            if (userLanguage.startsWith(FRENCH_VALUE.toLowerCase())) {
                toggleLanguage(FRENCH_VALUE.toLowerCase());
            } else if (userLanguage.startsWith(GERMAN_VALUE.toLowerCase())) {
                toggleLanguage(GERMAN_VALUE.toLowerCase());
            }
        }
    }, []);

    if (loading) return <></>;

    if (!cardProfileData) return <div>Card Profile not found</div>;

    const profileBasic: CardProfileBasic | undefined = cardProfileData.profileBasic;
    const profileContacts: CardProfileContact[] = cardProfileData.profileContacts ?? [];
    const profileSocialLinks: CardProfileSocialLink[] = cardProfileData.profileSocialLinks ?? [];
    const profileAddresses: CardProfileAddress | undefined = cardProfileData.profileAddresses;
    const profileAbout: CardProfileAbout | undefined = cardProfileData.profileAbout;
    const profileStyle: CardProfileStyle | undefined = cardProfileData.profileStyle;
    const toggle: CardProfileToggle | undefined = cardProfileData.profileToggle;

    const coverStyle = {
        backgroundColor: profileStyle?.coverBgColor ?? '#cdcdcd'
    };

    const handleSaveContact = () => saveContact(
        profileContacts, profileSocialLinks, profileBasic, profileAddresses, profileAbout, profilePhoto, toggle, handleAddContactSaveTrack
    );

    if (isUnassigned) return (
        <ActiveYourCardProfile
            uuid={cardProfileData?.uuid ?? ''}
            footer={
                <>
                    <PreviewFooter
                        profileStyle={cardProfileData?.profileStyle}
                        sx={styles.noMargins}
                    />
                    <div className={styles.footerActivateProfile}>
                        <a href={privacyPolicyURL} target="_blank" rel="noopener noreferrer">
                            {i18n.t('settings.termsAndConditions')}
                        </a>
                        <span>•</span>
                        <a href={privacyPolicyURL} target="_blank" rel="noopener noreferrer">
                            {i18n.t('settings.privacyPolicy')}
                        </a>
                    </div>
                </>
            }
        />
    );

    return (
        <ToggleProvider toggle={toggle}>
            <div className={styles.container}>
                <div className={styles.card} style={{backgroundColor: profileStyle?.cardBgColor}}>
                    <div className={styles.imageWrapper}>
                        <div className={styles.coverPhoto} style={coverStyle}>
                            {coverPhoto && <img src={coverPhoto} alt='cover-photo' className={styles.cover}/>}
                        </div>
                        {profilePhoto ?
                            <div className={styles.profilePhotoWrapper}>
                                <img src={profilePhoto} alt='profile-photo' className={styles.profilePhoto}/>
                            </div>
                            : <div className={styles.noProfilePhotoWrapper}></div>
                        }
                    </div>
                    <div className={styles.cardBodyWrapper}>
                        <CardProfileContent
                            profileBasic={profileBasic}
                            profileContacts={profileContacts}
                            profileSocialLinks={profileSocialLinks}
                            profileAddresses={profileAddresses}
                            profileAbout={profileAbout}
                            profileStyle={profileStyle}
                            isEmptyContent={isEmptyProfile(cardProfileData)}
                            onSave={handleSaveContact}
                            cardProfile={cardProfileData}
                        />
                    </div>
                    <div className={styles.footer}>
                        <a href={privacyPolicyURL} target="_blank" rel="noopener noreferrer">
                            {i18n.t('settings.termsAndConditions')}
                        </a>
                        <span>•</span>
                        <a href={privacyPolicyURL} target="_blank" rel="noopener noreferrer">
                            {i18n.t('settings.privacyPolicy')}
                        </a>
                    </div>
                </div>
            </div>
        </ToggleProvider>
    );
};

export default PreviewCardProfile;