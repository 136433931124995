import {CardProfileBasic} from './cardProfile';

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const DEACTIVATED = 'DEACTIVATED';
export const DELETED = 'DELETED';
export const UNASSIGNED = 'UNASSIGNED';
export const BOTH = 'BOTH';

export interface UserCardProfileFilter {
    searchByName?: string,
    status: CardProfileFilterStatus,
    page: number
}

export interface CardProfileApiFilter {
    searchByName?: string,
    status: CardProfileApiFilterStatus,
    page: number
}

export const CARD_PROFILE_FILTER_STATUSES: CardProfileFilterStatus[] = [ACTIVE, DEACTIVATED, BOTH];

export type CardProfileFilterStatus =
    typeof ACTIVE |
    typeof DEACTIVATED |
    typeof BOTH;

export type CardProfileApiFilterStatus =
    typeof ACTIVE |
    typeof INACTIVE |
    typeof BOTH;

export type CardProfileStatus =
    typeof ACTIVE |
    typeof INACTIVE |
    typeof UNASSIGNED |
    typeof DELETED;

export interface UserCardProfile {
    cardProfileId: number
    basic: CardProfileBasic;
    status: CardProfileStatus;
    uuid: string;
    owner: {
        id: number;
        email: string;
    }
}

export interface UserCardProfilesPagination {
    profiles: UserCardProfile[];
    noofElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export interface DeleteModal {
    isOpen: boolean;
    cardProfileId: number | null;
}