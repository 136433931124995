import {
    Basic,
    CardProfile,
    CardProfileAbout, CardProfileAddress,
    CardProfileAddressUpdate, CardProfileAssignRequest,
    CardProfileBasic,
    CardProfileContact,
    CardProfileContactUpdate, CardProfileFullname,
    CardProfileSocialLink,
    CardProfileSocialLinksUpdate,
    CardProfileStyle,
    CardProfileStyleUpdate,
    CardProfileToggle,
    Team
} from '../../types/cardProfile';
import {isEmpty} from '../../utils/common';
import {OTHER_WEBSITE, WEBSITE} from '../../types/contacts';

export const CardProfileConverter = {
    convertFromCardProfileApiToCardProfile(data: any): CardProfile | null {
        if (!data) return null;
        const profile = data.profile;

        return {
            id: profile.id,
            status: profile.status,
            profileAbout: CardProfileConverter.convertFromCardProfileApiToCardProfileAbout(profile.profileAbout),
            profileBasic: CardProfileConverter.convertFromCardProfileApiToCardProfileBasic(profile.profileBasic),
            profileStyle: CardProfileConverter.convertFromCardProfileApiToCardProfileStyle(profile.profileStyle),
            team: CardProfileConverter.convertFromCardProfileApiToTeam(profile.team),
            profileContacts: CardProfileConverter.convertFromCardProfileApiToCardProfileContacts(profile.profileContacts),
            profileSocialLinks: CardProfileConverter.convertFromCardProfileApiToCardProfileSocialLinks(profile.profileSocialLinks),
            profileAddresses: CardProfileConverter.convertFromCardProfileApiToCardProfileAddresses(profile.profileAddresses),
            user: {
                id: profile.webUser?.id,
                keycloakId: profile.webUser?.keycloakUserId,
                userEmail: profile.webUser?.userEmail
            },
            profileToggle: CardProfileConverter.convertFromCardProfileApiToCardProfileToggle(profile.profileToggle),
            uuid: data.uuid,
            assignToUserPending: data.assignToUserPending
        };
    },
    convertFromCardProfileApiToCardProfileBasic(data: any): CardProfileBasic | undefined {
        if (!data) return undefined;

        return {
            id: data.id,
            company: data.company,
            coverPhotoPath: data.coverPhotoPath,
            fullname: data.fullname,
            position: data.position,
            department: data.department,
            profilePhotoPath: data.profilePhotoPath
        };
    },
    convertFromCardProfileApiToCardProfileStyle(data: any): CardProfileStyle | undefined {
        if (!data) return undefined;

        return {
            id: data.id,
            cardBgColor: data.cardBgColor,
            cardTextColor: data.cardTextColor,
            btnBgColor: data.btnBgColor,
            btnTextColor: data.btnTextColor,
            iconsBgColor: data.iconsBgColor,
            iconsColor: data.iconsColor,
            coverBgColor: data.coverBgColor,
            companyBgColor: data.companyBgColor,
            companyTextColor: data.companyTextColor
        };
    },
    convertFromCardProfileApiToCardProfileAbout(data: any): CardProfileAbout | undefined {
        if (!data) return undefined;

        return {
            // id: data.id,
            text: data.text
        };
    },
    convertFromCardProfileApiToTeam(data: any): Team | undefined {
        if (!data) return undefined;

        return {
            id: data.id,
            name: data.name,
            creator: data.creator
        };
    },
    convertFromCardProfileApiToCardProfileContacts(dataList: any): CardProfileContact[] | undefined {
        if (isEmpty(dataList)) return undefined;
        let isFirstWebsite = true;

        return (dataList as any[]).map(data => {
            const displayType = (!isFirstWebsite && data.type === WEBSITE) ? OTHER_WEBSITE : data.type;
            if (data.type === WEBSITE) {
                isFirstWebsite = false;
            }

            return ({
                id: data.id,
                type: data.type,
                displayType: displayType,
                details: data.details
            });
        });
    },
    convertFromCardProfileApiToCardProfileSocialLinks(dataList: any): CardProfileSocialLink[] | undefined {
        if (isEmpty(dataList)) return undefined;

        return (dataList as any[]).map(data => ({
            id: data.id,
            type: data.type,
            details: data.details
        }));
    },
    convertFromCardProfileApiToCardProfileAddresses(data: any): CardProfileAddress | undefined {     
        if (isEmpty(data)) return undefined;

        return {
            id: data[0].id,
            address: data[0].address,
            country: data[0].country,
            city: data[0].city,
            zip: data[0].zip
        };
    },
    convertFromCardProfileApiToCardProfileToggle(data: any): CardProfileToggle | undefined {
        if (!data) return undefined;

        return {
            about: data.about,
            contact: data.contact,
            socialLinks: data.socialLinks
        };
    },
    convertFromCardProfilesApiToCardProfileFullname(data: any): CardProfileFullname[] {
        if (isEmpty(data)) return [];

        return (data as any[]).map(d => ({
            id: d.id,
            fullname: d.fullname
        }));
    },
    convertToContactSectionData(profileContacts: CardProfileContactUpdate[], profileAddress: CardProfileAddress | undefined): any {
        return {
            profileContacts: profileContacts,
            profileAddress: profileAddress
        };
    },
    convertToSocialLinksUpdate(socialLinks: CardProfileSocialLink[]): CardProfileSocialLinksUpdate[] {
        return socialLinks.map(link => ({ type: link.type, details: link.details }));
    },
    convertToContactUpdate(contacts: CardProfileContact[]): CardProfileContactUpdate[] {
        return contacts.map(contact => ({ type: contact.type, details: contact.details }));
    },
    convertToAddressUpdate(address?: CardProfileAddress): CardProfileAddressUpdate {
        return {
            address: address?.address,
            country: address?.country,
            city: address?.city,
            zip: address?.zip
        };
    },
    convertToAppearanceUpdate(style?: CardProfileStyle): CardProfileStyleUpdate {
        return {   
            cardBgColor: style?.cardBgColor,
            cardTextColor: style?.cardTextColor,
            btnBgColor: style?.btnBgColor,
            btnTextColor: style?.btnTextColor,
            iconsBgColor: style?.iconsBgColor,
            iconsColor: style?.iconsColor,
            coverBgColor: style?.coverBgColor,
            companyBgColor: style?.companyBgColor,
            companyTextColor: style?.companyTextColor
        };
    },
    convertToBasic(profileBasic: CardProfileBasic | undefined): Basic {
        if (!profileBasic) {
            return {};
        }

        return {
            company: profileBasic.company,
            fullname: profileBasic.fullname,
            position: profileBasic.position,
            department: profileBasic.department
        };

    },
    convertToCardProfileAssignRequest(userEmail: string): CardProfileAssignRequest {
        return {
            userEmail: userEmail
        };
    }
};
