import {FC} from 'react';
import styles from './order-new-card-button.module.scss';
import i18n from '../../../translations/i18n';
import CustomButton from './CustomButton';
import {ICARDS_FULL_URL, ICARDS_WEBSHOP_URL} from '../../../constants/common';
import {openInNewTab} from '../../../utils/common';

const OrderNewCardButton: FC = () => {

    return (
        <a href={ICARDS_WEBSHOP_URL} target='_blank'>
            <CustomButton
                sx={styles.btn}
                text={i18n.t('myCards.orderNewCard')}
                onClick={() => openInNewTab(ICARDS_FULL_URL)}
            />
        </a>
    );
};

export default OrderNewCardButton;