import React, {FC, useMemo, useState} from 'react';
import containerStyles from '../settings.module.scss';
import {CompanyMember, CompanyRoleType} from '../../../types/company';
import styles from './member.module.scss';
import { ReactComponent as DefaultProfileIcon } from '../../../assets/icons/preview-avatar.svg';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/icons/three-dots.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow/arrow-down.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import {Selector} from '../../common/selector/Selector';
import {COMPANY_ROLE_ITEMS, COMPANY_ROLE_OWNER} from '../../../constants/accountRole';
import {selectedItemStyle, selectStyle} from './SelectorStyle';
import {WarningModal} from '../../common/warningModal/WarningModal';
import i18n from '../../../translations/i18n';
import {updateCompanyMemberRole} from '../../../api/company';
import {CompanyConverter} from '../../../converters/api/CompanyConverter';
import {IS_COMPANY_OWNER} from '../../../store/utils/userSession';

const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

interface MemberProps {
    companyMember: CompanyMember,
    isLargeSizeScreen: boolean,
    companyId: number
}

interface ChangeRoleModal {
    role: CompanyRoleType,
    userId: number,
    fullname: string
}

const Member: FC<MemberProps> = ({
    companyMember, isLargeSizeScreen, companyId
}) => {
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [changeRoleModal, setChangeRoleModal] = useState<ChangeRoleModal | undefined>(undefined);
    const [resetSelector, setResetSelector] = useState<number>(0);

    const roleItems = useMemo(() => COMPANY_ROLE_ITEMS.map(fi => ({value: fi, name: fi})), []);
    const isCompanyOwner = useMemo(() => IS_COMPANY_OWNER(), []);

    const profilePhotoPath = companyMember.user.profilePhotoPath;
    const profilePhotoUrl = (imageBaseUrl && profilePhotoPath) ? (imageBaseUrl + profilePhotoPath) : '';

    const userId = companyMember.user?.id;
    const fullname = companyMember.user?.fullname;
    const email = companyMember.user?.email;
    const role = companyMember.role;
    const title = companyMember.title;

    const handleToggleMenu = () => setOpenMenu(prevState => !prevState);

    const handleCloseChangeRoleModal = () => {
        setChangeRoleModal(undefined);
        setResetSelector(prevState => prevState + 1);
    };

    const handleOnConfirmChangeRoleModal = () => {
        changeRoleModal && updateCompanyMemberRole(
            companyId,
            CompanyConverter.convertFromCompanyMemberToCompanyMemberUpdater(changeRoleModal.userId, changeRoleModal.role)
        ).then(() => setChangeRoleModal(undefined));
    };

    const handleRoleSelect = (value: string) => (
        setChangeRoleModal({role: value as CompanyRoleType, userId, fullname})
    );

    return (
        <div className={`${containerStyles.cardContainer} ${styles.container}`}>
            <div className={`${styles.imageBox} ${styles.image}`}>
                {profilePhotoUrl
                    ? <img className={styles.image} src={profilePhotoUrl} alt='profile-photo' />
                    : <DefaultProfileIcon  className={styles.image} />
                }
            </div>
            <div className={styles.containerWrapper}>
                <div className={styles.userContainer}>
                    <div className={styles.nameContainer}>
                        <div className={`${styles.text} ${styles.textTitle}`}>
                            {fullname}
                        </div>
                        <div className={`${styles.text} ${styles.textSmall}`}>
                            {email}
                        </div>
                    </div>
                </div>
                {!isLargeSizeScreen &&
                    <div className={`${styles.titleContainer} ${styles.text} ${styles.textNormal}`}>
                        {title}
                    </div>
                }
                <div className={`${styles.roleContainer} ${styles.text} ${styles.textNormal}`}>
                    {!isCompanyOwner || role === COMPANY_ROLE_OWNER
                        ? role
                        : <Selector
                            items={roleItems}
                            defaultValue={role}
                            onSelect={handleRoleSelect}
                            formStyle={styles.selector}
                            icon={ArrowDownIcon}
                            selectStyle={selectStyle}
                            selectedItemStyle={selectedItemStyle}
                            notConvert
                            resetSelector={resetSelector}
                        />
                    }
                </div>
                {isLargeSizeScreen &&
                    <div className={`${styles.titleContainer} ${styles.text} ${styles.textNormal}`}>
                        {title}
                    </div>
                }
            </div>
            <div className={`${styles.menuContainer} ${openMenu && styles.menuActive}`} onClick={handleToggleMenu}>
                <ThreeDotsIcon className={styles.dotsIcon}/>
            </div>
            <WarningModal
                isOpen={changeRoleModal !== undefined}
                icon={<WarningIcon />}
                title={i18n.t('modal.areYouSure')}
                text={i18n.t('modal.addUserRoleText', {role: changeRoleModal?.role, username: changeRoleModal?.fullname })}
                confirmBtnText={i18n.t('common.save')}
                cancelBtnText={i18n.t('common.cancel')}
                onConfirm={handleOnConfirmChangeRoleModal}
                onCancel={handleCloseChangeRoleModal}
            />
        </div>
    );
};

export default Member;