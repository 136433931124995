import React, { FC, useState } from 'react';
import Header from '../layout/Header';
import styles from './about.module.scss';
import i18n from '../../../translations/i18n';
import {CardProfileAbout} from '../../../types/cardProfile';
import useToggleController from '../useToggleController';
import { useToggleContext } from '../../../context/toggle/ToggleProvider';
import { useCardProfileDataContext } from '../../../context/profile/CardProfileDataProvider';
import {TextArea} from '../../common/textArea/TextArea';
import {
    NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX
} from '../../../utils/validationUtils';

interface AboutProps {
    profileId: string,
    showIcon?: boolean
}

const About: FC<AboutProps> = ({
    profileId, showIcon
}) =>{
    
    const { saveToggleData } = useToggleController(profileId);
    const { profileToggle, setProfileToggle } = useToggleContext();
    
    const [isExtended, setIsExtended] = useState<boolean>(false);
    const [characterCount, setCharacterCount] = useState<number>(0);
    const { aboutData, setAboutData, setIsDirty } = useCardProfileDataContext();

    const handleInputChange = (value: string) => {
        setCharacterCount(value.length);
        setAboutData((prevData: CardProfileAbout) => ({
            ...prevData,
            text: value
        }));        
        setIsDirty(true);
    };

    const handleExtend = () => {
        setIsExtended((prevIsExtended) => !prevIsExtended);
    };

    const handleToggle = () => {
        const newToggleState = {
            ...profileToggle,
            about: !profileToggle?.about
        };
        saveToggleData(newToggleState);
        setProfileToggle(newToggleState);
    };

    return (
        <div className={`${styles.container} ${isExtended ? styles.extended : styles.collapsed}`}>
            <Header
                showIcon={showIcon}
                title={i18n.t('common.about')}
                onExtend={handleExtend}
                isExtended={isExtended}
                isToggled={profileToggle?.about}
                onToggle={handleToggle}
            />
            {isExtended && 
            <form className={styles.form}>
                <TextArea
                    notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                    label={i18n.t('common.about')}
                    labelText={`(${characterCount}/220 characters limit)`}
                    value={aboutData?.text}
                    onChange={(e) => handleInputChange(e.target.value)}
                    rows={4}
                    maxLength={220}
                />
            </form>
            }
        </div>
    );
};

export default About;