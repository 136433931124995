import React, {FC, useCallback} from 'react';
import styles from './notification-box.module.scss';
import i18n from '../../translations/i18n';
import {
    CARD_ACTIVATED_OWNER,
    CARD_ACTIVATED_USER,
    CARD_ASSIGNED, CARD_OWNER_RECEIVED,
    CARD_OWNER_TRANSFERRED,
    NotificationDto, NotificationType
} from '../../types/notification';
import {openInNewTab} from '../../utils/common';

interface NotificationBoxProps {
    notification: NotificationDto;
    readNotification: (id: number) => void;
}

const NotificationBox: FC<NotificationBoxProps> = ({
    notification, readNotification
}) => {
    const unread = !notification.read;

    const getTitle = useCallback((type: NotificationType) => {
        if (type === CARD_ACTIVATED_USER) return i18n.t('notification.cardActivatedUserTitle');
        if (type === CARD_ACTIVATED_OWNER) return i18n.t('notification.cardActivatedOwnerTitle');
        if (type === CARD_ASSIGNED) return i18n.t('notification.cardAssignedTitle');
        if (type === CARD_OWNER_TRANSFERRED) return i18n.t('notification.cardOwnerTransferredTitle');
        if (type === CARD_OWNER_RECEIVED) return i18n.t('notification.cardOwnerReceivedTitle');
        return '';
    }, [notification, i18n]);

    const getDesc = useCallback((type: NotificationType) => {
        if (type === CARD_ACTIVATED_USER) return i18n.t('notification.cardActivatedUserDesc');
        if (type === CARD_ACTIVATED_OWNER) return i18n.t('notification.cardActivatedOwnerDesc');
        if (type === CARD_ASSIGNED) return i18n.t('notification.cardAssignedDesc');
        if (type === CARD_OWNER_TRANSFERRED) return i18n.t('notification.cardOwnerTransferredDesc');
        if (type === CARD_OWNER_RECEIVED) return i18n.t('notification.cardOwnerReceivedDesc');
        return '';
    }, [notification, i18n]);

    const handleOpenNotification = () => {
        readNotification(notification.id);
        openInNewTab(notification.url);
    };

    return (
        <div key={notification.id} className={`${styles.container} ${unread ? styles.unread : styles.read}`} onClick={handleOpenNotification}>
            <div className={styles.title}>
                {getTitle(notification.type)}
            </div>
            <div className={styles.body}>
                {getDesc(notification.type)}
            </div>
            <div className={styles.timesAgoWrapper}>
                {unread &&
                    <div className={styles.timesAgoIndicator}></div>
                }
                <div className={styles.timesAgo}>
                    {notification.timeAgo}
                </div>
            </div>
        </div>
    );
};

export default NotificationBox;