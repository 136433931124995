import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Protected } from './Protected';

import Profile from '../pages/profile/Profile';
import WalletCard from '../pages/walletCard/WalletCard';
import { routes } from '../constants/routes';
import useLoginController from './useLoginController';
import { getUrl } from '../utils/config';
import MyCards from '../pages/cards/MyCards';
import Analytics from '../pages/analytics/Analytics';
import Settings from '../pages/settings/Settings';
import { CardProfileDataProvider } from '../context/profile/CardProfileDataProvider';
import { WalletCardDataProvider } from '../context/walletCard/WalletCardDataProvider';
import ActivationModal from '../components/activationModal/ActivationModal';
import JoinCompanyWrapper from '../components/joinCompanyWrapper/JoinCompanyWrapper';
import CompanyCards from '../pages/cards/CompanyCards';


const PageRouter = () => {
    const router = createBrowserRouter([
        {
            path: getUrl(routes.analytics),
            element:
            <JoinCompanyWrapper>
                <Protected component={ // HOMEPAGE, first page on login to show
                    <ActivationModal>
                        <Analytics />
                    </ActivationModal>
                } />
            </JoinCompanyWrapper>,
        },
        {
            path: getUrl(routes.myCards),
            element: <Protected component={<MyCards />} />,
        },
        {
            path: getUrl(routes.companyCards),
            element: <Protected component={<CompanyCards />} onlyForBusinessAccount />,
        },
        {
            path: getUrl(routes.settings),
            element: <Protected component={<Settings />} />,
        },
        {
            path: getUrl(routes.profile),
            element: (
                <Protected
                    component={
                        <CardProfileDataProvider>
                            <Profile />
                        </CardProfileDataProvider>
                    }
                    hasStickyMobileFooter={true}
                />
            ),
        },
        {
            path: getUrl(routes.walletCard),
            element: (
                <Protected
                    component={
                        <WalletCardDataProvider>
                            <WalletCard />
                        </WalletCardDataProvider>
                    }
                    hasStickyMobileFooter={true}
                />
            ),
        },
    ]);

    const { initialized } = useLoginController();

    if (!initialized) {
        return <div>Loading...</div>;
    }

    return <RouterProvider router={router} />;
};

export default PageRouter;
