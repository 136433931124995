import { useState } from 'react';
import {deleteCardProfileCoverImgById, deleteCardProfileImgById, saveProfilCardInfo} from '../../api/cardProfile';
import {
    Basic,
    CardProfileAbout,
    CardProfileSocialLinksUpdate,
    CardProfileStyleUpdate,
    ContactAndAdress
} from '../../types/cardProfile';
import {useAlert} from '../AlertProvider';
import {ERROR} from '../../constants/common';
import {isBlank} from '../../utils/common';

const useSaveController = (id: number) => {
    const {setAlert} = useAlert();
    const [loading, setLoading] = useState<boolean>(false);

    const saveProfileInfo = async (currentProfileImage: string, currentCoverImage: string,
        aboutData?: CardProfileAbout, basic?: Basic,
        profileImage?: File | null, coverImage?: File | null,
        socialLinks?: CardProfileSocialLinksUpdate[], contact?: ContactAndAdress, style?: CardProfileStyleUpdate) => {
        setLoading(true);
        const formData = new FormData();

        formData.append('basic', JSON.stringify(basic));
        formData.append('contact', JSON.stringify(contact));
        formData.append('style', JSON.stringify(style));

        if (aboutData) {
            formData.append('about', JSON.stringify(aboutData));
        }

        if (isBlank(currentProfileImage) && !profileImage) {
            await deleteCardProfileImgById(id);
        } else {
            if (profileImage) {
                formData.append('profileImage', profileImage);
            }
        }

        if (isBlank(currentCoverImage) && !coverImage) {
            await deleteCardProfileCoverImgById(id);
        } else {
            if (coverImage) {
                formData.append('coverImage', coverImage);
            }
        }

        if (socialLinks?.length !== 0) {
            formData.append('socialLinks', JSON.stringify(socialLinks));
        }

        saveProfilCardInfo(id, formData)
            .then((res) => {
                if (res.error) setAlert(ERROR, res.error.response.data.error);
            })
            .finally(() => setLoading(false));

    };

    return {
        saveProfileInfo,
        cardProfileLoading: loading
    };
};

export default useSaveController;
